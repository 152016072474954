<!--
  1.仅上架中的商品可参与赠品活动，参与赠品活动未结束时不可下架。
  2.赠品需精确到该商品某一规格，每个赠品活动仅支持一个商品一个规格。
  3.赠品库存不可大于该规格库存，新建赠品后该规格售卖库存需扣减对应库存，两边库存独立计算。创建赠品活动时不可修改库存。
  4.当赠品活动失效时，需将该活动剩余库存加回该商品售卖库存。
  5.手动失效或赠品剩余库存为0时，状态更改为已失效。
  6.买赠活动下单时对应扣减赠品库存。未支付取消时则返回库存，支付成功后不再返回库存
  7.领取有效天数指抽奖活动获得该赠品的有效兑换时间（暂无）。买赠活动无需考虑。
  8.已发放、已兑换：买赠活动支付成功时同步增加，抽奖活动等则需独自计算。
  9.手动失效的赠品活动不影响已发放的赠品兑换。
 -->
<template>
  <moe-page title="赠品管理">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">赠品管理</div>
            <div class="font-14">可以满足抽奖活动、日常营销赠送商品。</div>
          </div>
        </div>
      </template>

      <!-- 筛选信息 -->
      <moe-inquire @search="giftSearch">
        <el-form-item label="状态">
          <moe-select type="giftStateList" v-model="giftParams.listState" placeholder="选择状态"></moe-select>
        </el-form-item>
        <el-form-item label="赠品名称">
          <el-input v-model.trim="giftParams.name" placeholder="输入赠品名称" maxlength="50" clearable />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="giftTableRef" url="/shop/gift/list" :params="giftParams" :numberShow="false">
        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activityManage/giftManage/add')">新增赠品</el-button>

        <el-table-column label="赠品名称" prop="name" min-width="150" :show-overflow-tooltip="true" />

        <el-table-column label="赠品信息" min-width="200">
          <template slot-scope="{ row }">
            <div class="df aic jcc">
              <moe-image :src="row.goodsItemCover" width="80px" height="80px" />
              <div class="df1 tal ml-10">
                <div class="fwb">{{ row.goodsName }}</div>
                <div class="df aic">
                  <moe-tag class="mr-5" v-for="(specItem, specIndex) in row.goodsItemName.split(',')" :key="specIndex">{{ specItem }}</moe-tag>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="已发放" prop="issuedCount" width="100" />
        <el-table-column label="已兑换" prop="exchangedCount" width="100" />
        <el-table-column label="剩余库存" prop="stockLeft" width="100" />

        <el-table-column label="状态" width="100">
          <div :class="$moe_format.getGiftStateListColor(row.listState)" slot-scope="{ row }">
            {{ $moe_format.getGiftStateList(row.listState) }}
          </div>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="350">
          <div class="moe-table_btns" slot-scope="{ row }">
            <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/giftManage/detail`, { id: row.id })">查看</el-button>
            <template v-if="['PUBLISHED'].includes(row.listState)">
              <el-button type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/giftManage/add`, { id: row.id })">编辑活动</el-button>
              <el-button type="danger" size="mini" icon="el-icon-circle-close" @click="handleClose(row)">失效</el-button>
            </template>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageGiftManageList',
  data() {
    return {
      giftParams: {
        pageNum: 1,
        pageSize: 10,
        listState: '',
        name: '',
      }
    }
  },
  methods: {
    giftSearch(isSearch) {
      if (!isSearch) {
        this.giftParams = {
          pageNum: 1,
          pageSize: 10,
          listState: '',
          name: '',
        }
      }
      this.$refs['giftTableRef'].searchData();
    },
    handleClose({ id, name }) {
      this.$moe_layer.confirm(`您确定要失效当前选项"${name}"吗？`, () => {
        this.$moe_api.GIFTMANAGE_API.closeGift({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('失效成功');
            this.giftSearch(false);
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },
  }
}
</script>

<style>

</style>